import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Loader from './components/parts/loader/Loader';

const LayOut = lazy(() => import('./LayOut'));
const Home = lazy(() => import('./components/pages/Home'));
const Error404 = lazy(() => import('./components/pages/404'));
const About = lazy(() => import('./components/pages/About'));
const Services = lazy(() => import('./components/pages/Services'));
const Contact = lazy(() => import('./components/pages/Contact'));
const CutConstraction = lazy(() =>
	import('./components/pages/CutConstraction')
);
const MassiveWoodenBoards = lazy(() =>
	import('./components/pages/MassiveWoodenBoards')
);
const ProfiledAndPlanedWood = lazy(() =>
	import('./components/pages/ProfiledAndPlanedWood')
);
const GluLam = lazy(() => import('./components/pages/GluLam'));
const FingerJoint = lazy(() => import('./components/pages/FingerJoint'));

const HomeDe = lazy(() => import('./components/pages/de/Home'));
const AboutDe = lazy(() => import('./components/pages/de/About'));
const ServicesDe = lazy(() => import('./components/pages/de/Services'));
const ContactDe = lazy(() => import('./components/pages/de/Contact'));
const CutConstractionDe = lazy(() =>
	import('./components/pages/de/CutConstraction')
);
const MassiveWoodenBoardsDe = lazy(() =>
	import('./components/pages/de/MassiveWoodenBoards')
);
const ProfiledAndPlanedWoodDe = lazy(() =>
	import('./components/pages/de/ProfiledAndPlanedWood')
);
const GluLamDe = lazy(() => import('./components/pages/de/GluLam'));
const FingerJointDe = lazy(() => import('./components/pages/de/FingerJoint'));

const HomeEn = lazy(() => import('./components/pages/en/Home'));
const AboutEn = lazy(() => import('./components/pages/en/About'));
const ServicesEn = lazy(() => import('./components/pages/en/Services'));
const ContactEn = lazy(() => import('./components/pages/en/Contact'));
const CutConstractionEn = lazy(() =>
	import('./components/pages/en/CutConstraction')
);
const MassiveWoodenBoardsEn = lazy(() =>
	import('./components/pages/en/MassiveWoodenBoards')
);
const ProfiledAndPlanedWoodEn = lazy(() =>
	import('./components/pages/en/ProfiledAndPlanedWood')
);
const GluLamEn = lazy(() => import('./components/pages/en/GluLam'));
const FingerJointEn = lazy(() => import('./components/pages/en/FingerJoint'));

function App() {
	return (
		<Router>
			<Suspense fallback={<Loader />}>
				<div className='App'>
					<Routes>
						<Route path='/' element={<LayOut />}>
							<Route index element={<Home />} />
							<Route path='o-nama' element={<About />} />
							<Route path='proizvodi'>
								<Route index element={<Services />} />
								<Route path='rezana-gradja' element={<CutConstraction />} />
								<Route
									path='masivne-drvene-ploce'
									element={<MassiveWoodenBoards />}
								/>
								<Route
									path='profilisano-i-blanjirano-drvo'
									element={<ProfiledAndPlanedWood />}
								/>
								<Route path='glulam' element={<GluLam />} />
								<Route path='finger-joint' element={<FingerJoint />} />
							</Route>
							<Route path='kontakt' element={<Contact />} />
							<Route path='de'>
								<Route index element={<HomeDe />} />
								<Route path='uber-uns' element={<AboutDe />} />
								<Route path='dienstleistungen'>
									<Route index element={<ServicesDe />} />
									<Route path='schnittholz' element={<CutConstractionDe />} />
									<Route
										path='massivholzbretter'
										element={<MassiveWoodenBoardsDe />}
									/>
									<Route
										path='profiliertes-und-gehobeltes-holz'
										element={<ProfiledAndPlanedWoodDe />}
									/>
									<Route path='kleber' element={<GluLamDe />} />
									<Route path='fingergelenk' element={<FingerJointDe />} />
								</Route>
								<Route path='kontakt' element={<ContactDe />} />
							</Route>
							<Route path='en'>
								<Route index element={<HomeEn />} />
								<Route path='about-us' element={<AboutEn />} />
								<Route path='products'>
									<Route index element={<ServicesEn />} />
									<Route path='lumber' element={<CutConstractionEn />} />
									<Route
										path='massive-wooden-boards'
										element={<MassiveWoodenBoardsEn />}
									/>
									<Route
										path='profiled-and-planed-wood'
										element={<ProfiledAndPlanedWoodEn />}
									/>
									<Route path='gluelam' element={<GluLamEn />} />
									<Route path='finger-joint' element={<FingerJointEn />} />
								</Route>

								<Route path='contact' element={<ContactEn />} />
							</Route>
							<Route path='*' element={<Error404 />} />
						</Route>
					</Routes>
				</div>
			</Suspense>
		</Router>
	);
}

export default App;
